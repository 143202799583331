import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import FoodMenu from './components/FoodMenu';
import ProductsList from './components/ProductsList';
import Product from './components/Product';
import Cart from './components/Cart';
import Payment from './components/Payment';  
import './App.css'
import PaymentProcess from './components/PaymentProcess';
import SelectedProductsToPay from './components/SelectedProductsToPay';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/food-menu" element={<FoodMenu/>} />
        <Route path="/products" element={<ProductsList/>} />
        <Route path="/products/:id" element={<Product/>} />
        <Route path="/cart" element={<Cart/>} />
        <Route path="/payment" element={<Payment/>} />
        <Route path="/payment-process" element={<PaymentProcess/>} />
        <Route path="/selected-products-topay" element={<SelectedProductsToPay/>} />
      </Routes>
    </Router>
  )
}

export default App;

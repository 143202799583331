import React, { useEffect, useState } from 'react'
import Header from '../common/components/Header'
import { useDispatch, useSelector } from 'react-redux';
import './PaymentProcess.css';
import { createOperationService, createOperationSharedByChoiceService, createOperationSharedByplitService, createOperationSharedBySplitService } from '../common/apiServices/operationsService';
import { useNavigate } from 'react-router-dom';
import { countAllTotals, formatPrice, getAllProductsFromAllOrdersByTable } from './../common/helpers/utils';
import { addToCart } from '../redux/cartSlice';
import { addOperationInfos } from '../redux/operationSlice';
import { getOrdersByTableService } from '../common/apiServices/orders/order.service';

export default function PaymentProcess() {
    const { products } = useSelector((state) => state.cart?.cartItems)
    const operationsInfos = useSelector((state) => state?.operation?.operationInfos)

    const {id_table, num_table, type_payment_table, type_shared_payment} = useSelector((state) => state?.operation?.operationInfos?.tableInfos)
    const clientInfosSelector = useSelector((state) => state?.operation?.operationInfos?.clientInfos)
    const navigate = useNavigate();

    const dispatch = useDispatch()

    // START CUSTOM POURBOIR PROCESS
    // handle pourboire
    const [pourboire, setPourboire] = useState(0);
    const [isOtherPourboire, setIsOtherPourboire] = useState(false);
    const [customNumberValue, setCustomNumberValue] = useState('');
    const [showCustomPourboireValModal, setShowCustomPourboireValModal] = useState(false);

    useEffect(() => {
        // allow closing modal when click outside
        const handleOutsideClick = (e) => {
            if (showCustomPourboireValModal === true && e.target.classList.contains('modal')) {
                setShowCustomPourboireValModal(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showCustomPourboireValModal]);

    const handlePourboire = (pourboireValue) => {
        setIsOtherPourboire(false);
        if(pourboire === 0){
            setPourboire(pourboireValue)
        }else{
            setPourboire(0)
        }
        
    }

    // handle custom pourboire price value
    const handleChangeCustomNumberValue = (numValue) => {
        setCustomNumberValue((prev) => prev.concat(numValue))
    }

    const handleSubmitCustomNumberVlaue = () => {
        setPourboire(customNumberValue)
        // setCustomNumberValue('')

        // close popup
        setShowCustomPourboireValModal(false);
    }

    const handleRemoveCustomPourboireText = () => {
        setCustomNumberValue((currentText) => {
            return currentText.slice(0, currentText.length - 1)
        });

    }
    // END CUSTOM POURBOIR PROCESS

    const calculateSubTotal = () => {
        if(operationsInfos?.tableInfos && operationsInfos?.clientInfos){
            const {tableInfos, clientInfos} = operationsInfos;
            if(tableInfos?.type_payment_table === "OWN"){
                // return products.reduce((total, prod) => total + (prod.price * prod.quantity), 0);
                // return countAllTotals(products)
                return countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList))
            }else{
                if(tableInfos?.type_shared_payment === "CHOICE"){
                    // return clientInfos?.selectedProdsToPay.reduce((total, prod) => total + (prod.price * prod.quantity), 0);
                    console.log("clientInfos?.selectedProdsToPay :", clientInfos?.selectedProdsToPay)
                    return countAllTotals(clientInfos?.selectedProdsToPay)
                }else{
                    // return clientInfos?.splittedAmountToPay
                    console.log("clientInfos?.splittedAmountToPay :", clientInfos?.splittedAmountToPay)
                    // return countAllTotals(clientInfos?.splittedAmountToPay)
                    return clientInfos?.splittedAmountToPay
                }
            }
        }
    }

    const calculateTotal = () => {
        if(operationsInfos?.tableInfos){
            const {tableInfos, clientInfos} = operationsInfos;
            if(tableInfos?.type_payment_table === "OWN"){
                return countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList))
                // return countAllTotals(products) + Number(pourboire);
            }else{
                if(tableInfos?.type_shared_payment === "CHOICE"){
                    // return clientInfos?.selectedProdsToPay?.reduce((total, prod) => total + (prod.price * prod.quantity), 0) + Number(pourboire);
                    return countAllTotals(getAllProductsFromAllOrdersByTable(clientInfos?.selectedProdsToPay))
                }else{
                    return countAllTotals(getAllProductsFromAllOrdersByTable(clientInfos?.splittedAmountToPay))
                    // return clientInfos?.splittedAmountToPay + Number(pourboire)
                }
            }
        }
    }

    const sendPayAllOperation = () => {
        const bodyData = {
            tableInfos: {
                id_table: id_table,
                num_table: num_table,
                type_payment_table: type_payment_table,
                type_shared_payment: type_shared_payment,
            },
            payment_method: "visa",
            num_card: `1723 3445 2135 ${Math.floor(Math.random() * 9000)}`,
            pourboire: pourboire && Number(pourboire),
            subTotal: countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)),
            total: countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)) + Number(pourboire),
            status_id: 5, // by default set as "id" of "en attente".
            status_pos: "Non Notifié",
            client_id: clientInfosSelector?.id_client,
        }

        // call service here and pass it a body params
        createOperationService(bodyData)
            .then(() => {
                navigate('/');

                // clear cart items from locaStorage synchronizing redux store (closed by order)
                let cartItemsStorage = JSON.parse(localStorage.getItem('cart'));
                cartItemsStorage = {
                    ...cartItemsStorage,
                    products: [],
                    count: 0
                }
                localStorage.setItem('cart', JSON.stringify(cartItemsStorage));
                dispatch(addToCart(cartItemsStorage));
                
                // clear table & client_infos
                let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                if(operationInfosStorage){
                    operationInfosStorage = {
                        ...operationInfosStorage,
                        clientInfos: {},
                        tableInfos: {}
                    }

                    // Save the updated clientInfos back to localStorage
                    localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                }
                // dispatch action to synch redux store
                dispatch(addOperationInfos(operationInfosStorage))

            })
            .catch((err) => console.log(err))
    }

    const sendPaySharedByChoiceOperation = () => {
        const bodyData = {
            tableInfos: {
                id_table: id_table,
                num_table: num_table,
                type_payment_table: type_payment_table,
                type_shared_payment: type_shared_payment,
            },
            payment_method: "visa",
            num_card: `1723 3445 2135 ${Math.floor(Math.random() * 9000)}`,
            pourboire: pourboire && Number(pourboire),
            subTotal: calculateSubTotal(),
            total: calculateSubTotal() + Number(pourboire),
            status_id: 5, // by default set as "id" of "en attente".
            status_pos: "Non Notifié",
            client_id: clientInfosSelector?.id_client,
            totalOfTotalsToPay: countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)),
            ordersOfSelectedProductsToPay: clientInfosSelector?.selectedProdsToPay
        }

        // call service here and pass it a body params
        createOperationSharedByChoiceService(bodyData)
            .then((data) => {
                if(data?.code === 0){
                    // here clear all table and client {}
                    // clear cart items from locaStorage synchronizing redux store (closed by order)
                    let cartItemsStorage = JSON.parse(localStorage.getItem('cart'));
                    cartItemsStorage = {
                        ...cartItemsStorage,
                        products: [],
                        count: 0
                    }
                    localStorage.setItem('cart', JSON.stringify(cartItemsStorage));
                    dispatch(addToCart(cartItemsStorage));

                    // navigate to home
                    navigate('/');
                }else{
                    // here clear just selectedProductsToPay []
                    let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                    if(operationInfosStorage){
                        operationInfosStorage = {
                            ...operationInfosStorage,
                            clientInfos: {
                                ...operationInfosStorage.clientInfos,
                                selectedProdsToPay: []
                            }
                        }
    
                        // Save the updated clientInfos back to localStorage
                        localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                    }
                    // dispatch action to synch redux store
                    dispatch(addOperationInfos(operationInfosStorage))

                    // navigate to home
                    navigate('/');
                }
            })
            .catch((err) => console.log(err))
    }

    const sendPaySharedBySplitOperation = () => {
        const bodyData = {
            tableInfos: {
                id_table: id_table,
                num_table: num_table,
                type_payment_table: type_payment_table,
                type_shared_payment: type_shared_payment,
            },
            payment_method: "visa",
            num_card: `1723 3445 2135 ${Math.floor(Math.random() * 9000)}`,
            pourboire: pourboire && Number(pourboire),
            subTotal: calculateSubTotal(),
            total: calculateSubTotal() + Number(pourboire),
            status_id: 5, // by default set as "id" of "en attente".
            status_pos: "Non Notifié",
            client_id: clientInfosSelector?.id_client,
            totalOfTotalsToPay: countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)),
            ordersOfSplittedAmountToPay: ordersDataByTableList && ordersDataByTableList.map(item => item.id_order)
        }

        // call service here and pass it a body params
        createOperationSharedBySplitService(bodyData)
            .then((data) => {
                if(data?.code === 0){
                    // here clear all table and client {}
                    // clear cart items from locaStorage synchronizing redux store (closed by order)
                    let cartItemsStorage = JSON.parse(localStorage.getItem('cart'));
                    cartItemsStorage = {
                        ...cartItemsStorage,
                        products: [],
                        count: 0
                    }
                    localStorage.setItem('cart', JSON.stringify(cartItemsStorage));
                    dispatch(addToCart(cartItemsStorage));

                    // here clear all clientInfos and tableInfos
                    let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                    if(operationInfosStorage){
                        operationInfosStorage = {
                            ...operationInfosStorage,
                            clientInfos: {},
                            tableInfos: {}
                        }
    
                        // Save the updated clientInfos back to localStorage
                        localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                    }
                    // dispatch action to synch redux store
                    dispatch(addOperationInfos(operationInfosStorage))

                    // navigate to home
                    navigate('/');
                }else{
                    // here clear just selectedProductsToPay []
                    let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos'));
                    if(operationInfosStorage){
                        operationInfosStorage = {
                            ...operationInfosStorage,
                            clientInfos: {
                                ...operationInfosStorage.clientInfos,
                                splittedAmountToPay: 0
                            }
                        }
    
                        // Save the updated clientInfos back to localStorage
                        localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                    }
                    // dispatch action to synch redux store
                    dispatch(addOperationInfos(operationInfosStorage))

                    // navigate to home
                    navigate('/');
                }
            })
            .catch((err) => console.log(err))
    }

    const handleCreateOperationByRightOption = () => {
        if(type_payment_table === "OWN" && type_shared_payment === null){
            sendPayAllOperation();
        }else{
            if(type_shared_payment === "CHOICE"){
                sendPaySharedByChoiceOperation()
            }else{
                sendPaySharedBySplitOperation()
            }
        }
    }
    // show total to pay all products of all orders by table
    const [ordersDataByTableList, setOrdersDataByTableList] = useState([]);

    // add useEffect 2 to handle Total products by table api
    useEffect(() => {
        // call api get all products of all orders by table where not 'free'
        getOrdersByTableService(id_table)
            .then((orders) => {
                setOrdersDataByTableList(orders);
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <div style={{ backgroundColor: '#f7f6fa' }}>
            <Header redirectTo="/payment" />
            <div className='img-payment bg-white'
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    paddingTop: "10px"
                }}
            >
                <h3 className='text text-center'
                    style={{
                        fontSize: "19px",
                        fontWeight: "800px",
                        fontFamily: "Poppins",
                        paddingTop: "15px"
                    }}
                >
                    Laisser un pourboire au serveur?
                </h3>
            </div>
            {/* until here is good go to list */}
            <div className='container-fluid p-3 bg-white'>
                <div>
                    <ul class="list-group">
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                border: "1px solid #00000036",
                                borderRadius: "10px",
                                margin: "5px",
                                padding: "16px",
                                cursor: "pointer",
                                background: pourboire == 10 && '#FCC54630'
                            }}
                            onClick={() => handlePourboire(10)}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >Bon service</p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >10.00 Dhs</p>
                        </li>
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                border: "1px solid #00000036",
                                borderRadius: "10px",
                                margin: "5px",
                                padding: "16px",
                                cursor: "pointer",
                                background: pourboire == 15 && '#FCC54630'
                            }}
                            onClick={() => handlePourboire(15)}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                Très bon service
                            </p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                15.00 Dhs
                            </p>
                        </li>
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                border: "1px solid #00000036",
                                borderRadius: "10px",
                                margin: "5px",
                                padding: "16px",
                                cursor: "pointer",
                                background: pourboire == 25 && '#FCC54630'
                            }}
                            onClick={() => handlePourboire(25)}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >Incroyable service</p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >25.00 Dhs</p>
                        </li>
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                border: "1px solid #00000036",
                                borderRadius: "10px",
                                margin: "5px",
                                padding: "16px",
                                cursor: "pointer",
                                background: isOtherPourboire ? '#FCC54630' : null
                            }}
                            onClick={() => {
                                setPourboire(0);
                                setIsOtherPourboire(true);
                                setShowCustomPourboireValModal(true)
                            }}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >Autre</p>
                            <img src="images/pen-icon.svg" alt="pen_icon" />
                        </li>
                    </ul>
                    {/* START MODAL CUSTOM Pourboire */}
                    {
                        showCustomPourboireValModal && (
                            <div
                                className="modal fade show"
                                // id="confirmationModal"
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                                style={{
                                    display: 'block',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    //  width:"400px",
                                    //  height:"600px"
                                }}
                            >
                                <div class="modal-dialog"
                                    style={{
                                        position: "fixed",
                                        bottom: -15,
                                        left: "49%",
                                        transform: "translateX(-50%)",
                                        width: "100%",
                                    }}
                                >
                                    <div class="modal-content"
                                        style={{ borderColor: "none", }}
                                    >
                                        <div class="modal-header">
                                            <h5 class="modal-title text tex-center" id="customPourboireModalLabel"
                                                style={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "22px",
                                                    fontWeight: "600",
                                                }}
                                            >Saisissez un montant</h5>
                                            <img
                                                src="images/close-icon.svg"
                                                data-bs-dismiss="modal" aria-label="Close" alt="close-modal-icon"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setShowCustomPourboireValModal(false)}
                                            />
                                        </div>
                                        <div class="modal-body">
                                            <div>
                                                <div className="custom-number-value"
                                                    style={{
                                                        padding: "15px",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <h3 style={{ color: 'gray' }}>
                                                        {`${customNumberValue} $`}
                                                    </h3>
                                                </div>
                                                <p className='text text-center'>Total de la commande : {calculateSubTotal()} Dhs</p>
                                            </div>
                                            <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                                                <button
                                                    class="btn btn-primary "
                                                    data-bs-dismiss="modal" aria-label="Close"
                                                    type="button"
                                                    style={{
                                                        fontSize: "15px",
                                                        fontFamily: "Poppins",
                                                        fontWeight: "400",
                                                        background: "white",
                                                        border: " solid black 1px",
                                                        marginTop: "5px",
                                                        padding: "10px",
                                                        background:"linear-gradient(to right, #FCC546, #FDD681)",
                                                        color: '#1E1E1E',
                                                        border: "none",
                                                        width: "80%"
                                                    }}
                                                    onClick={handleSubmitCustomNumberVlaue}
                                                >Enregistrer</button>
                                            </div>
                                            <div className='number-inputs mt-3'>
                                                <div className='boxes_container'
                                                    style={{
                                                        backgroundColor: 'lightgray',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        flexWrap: 'wrap',
                                                        padding: '3px',
                                                        borderRadius: '5px'
                                                    }}
                                                >
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('1')}
                                                    >
                                                        <h3>1</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('2')}
                                                    >
                                                        <h3>2</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('3')}
                                                    >
                                                        <h3>3</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('4')}
                                                    >
                                                        <h3>4</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('5')}
                                                    >
                                                        <h3>5</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('6')}
                                                    >
                                                        <h3>6</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('7')}
                                                    >
                                                        <h3>7</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('8')}
                                                    >
                                                        <h3>8</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('9')}
                                                    >
                                                        <h3>9</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'lightgray',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                    // onClick={() => handleChangeCustomNumberValue('1')}
                                                    >
                                                        <h3></h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'white',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => handleChangeCustomNumberValue('0')}
                                                    >
                                                        <h3>0</h3>
                                                    </div>
                                                    <div className='num_box' style={{
                                                        background: 'lightgray',
                                                        textAlign: 'center',
                                                        margin: '4px',
                                                        width: '140px',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={handleRemoveCustomPourboireText}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="35" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                                                            <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z"/>
                                                            <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {/* END MODAL */}

                </div>
            </div>
            {/* Start payment method section */}
            <div className='container-fluid p-3 bg-white'
                style={{
                    marginTop: "10px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px inset",
                }}
            >
                <p style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "800",
                    lineHeight: "32px",
                    paddingLeft: "15px"

                }}>Moyen de paiement</p>
                <ul class="list-group">
                    
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "none"
                        }}
                    >
                        <label class="form-check-label" for="secondRadio"
                            style={{
                                fontSize: "22px",
                                fontWeight: "600"
                            }}
                        >Carte de crédit
                        </label>
                        <input class="form-check-input me-1" type="radio" checked name="paymentMethod" value="" id="secondRadio" />
                    </li>
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "none"
                        }}
                    >
                        <div>
                            <img src="/images/pay-radio-icon.svg" alt="pay_img" width={50} height={30}  /> / 
                            <img src="/images/google-pay-icon.svg" alt="pay_img" width={50} height={30} style={{marginLeft: 7}}/>
                        </div>
                        <input class="form-check-input me-1" type="radio" name="paymentMethod" value="" id="firstRadio" />
                    </li>
                </ul>
            </div>
            {/* until here is good go to list 3 */}
            <div className='container-fluid p-3 bg-white'
                style={{
                    marginTop: "10px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px inset",
                }}
            >
                <div className=''>
                    <ul class="list-group list-group-flush">
                        <li class="list-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                <b>Sous-total</b>
                            </p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                {/* <b>{`${formatPrice(countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)))} Dh`}</b> */}
                                <b>{`${formatPrice(calculateSubTotal())} Dhs`}</b>
                            </p>
                        </li>
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                Pourboire
                            </p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                {`${formatPrice(pourboire)} Dhs`}
                            </p>
                        </li>
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "18px",
                                fontWeight: "800",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >Total</p>
                            <p style={{
                                fontSize: "18px",
                                fontWeight: "800",
                                fontFamily: "Poppins",
                                margin: 0,
                                background: "linear-gradient(to right, #FCC546, #FDD681)",
                                WebkitBackgroundClip: 'text',
                                color: 'transparent',
                                display: 'inline-block',
                                WebkitTextFillColor: 'transparent', // For Safari
                            }}
                            >
                                {/* {`${formatPrice(calculateTotal())} Dh`} */}
                                {/* {`${formatPrice(countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)) + Number(pourboire))} Dh`} */}
                                {`${formatPrice(calculateSubTotal() + Number(pourboire))} Dhs`}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            {/* until here is good go to button */}
            <div className='container-fluid p-4 bg-white text text-center mt-2'>
                <button
                    className="btn btn-lg"
                    style={{
                        paddingLeft: "90px",
                        paddingRight: "90px",
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        borderColor: "white",
                        background:"linear-gradient(to right, #FCC546, #FDD681)",
                        color: '#1E1E1E',
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "600",
                    }}
                    type="button"
                    // onClick={sendPayAllOperation}
                    onClick={handleCreateOperationByRightOption}
                    disabled={(calculateSubTotal() + Number(pourboire)) === 0}
                >
                    Payer 
                    {/* {`${formatPrice(calculateTotal())} Dh`} */}
                    {/* {` ${formatPrice(countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList)) + Number(pourboire))} Dh`} */}
                    {` ${formatPrice(calculateSubTotal() + Number(pourboire))} Dhs`}
                </button>
            </div>
        </div>
    )
}
